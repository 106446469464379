<template>
  <Login
    googleText="Sign up with Google"
    twitterText="Sign up with Twitter"
    emailText="Or Sign up with Email"
    title="You’ve been invited to join RadiTube"
    btnText="Sign Up"
  />
</template>

<script>
  import Login from '@/components/login/Login'

  export default {
    components: { Login },
    name: 'Invite',
    mounted(){
      if(this.$route.params.id){
        this.$localStorage.set('inviteToken', this.$route.params.id)
      }
    }

  }
</script>

<style lang="scss" scoped>
.labelGrey{
  &:before{
    left: 0;
  }
}
</style>